import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap";
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import NewsList from '../components/NewsList';

export default function News({ data }) {

    return (
        <div>
            <Layout title="News at Shanti-Aid">
                <Container>
                    <section id="home-icons" className="py-5">
                        <Container>
                            <div className="row">
                                <div className="col-md-1 mb-1 text-center"></div>
                                <div className="col-md-10 mb-1 text-center">
                                    <h1>News at Shanti-Aid</h1>
                                    <NewsList />
                                </div>
                                <div className="col-md-1 mb-1 text-center"></div>
                            </div>
                        </Container>
                    </section>
                </Container>
            </Layout>
        </div>
    )
}

export const query = graphql`
  {
    allMdx(sort: {order: DESC, fields: frontmatter___date}) {  
      edges {
        node {
          id
          slug
          excerpt
        }
      }
    }
  }
`

